// Spinner
// ------------------------------
@import 'mixins';

/* stylelint-disable-next-line scss/at-mixin-pattern */
@mixin Select-spinner($size, $orbit, $satellite)
{
	@include animation( Select-animation-spin 400ms infinite linear );
	@include square($size);

	box-sizing: border-box;
	border-radius: 50%;
	border: floor(calc($size / 8)) solid $orbit;
	border-right-color: $satellite;
	display: inline-block;
	position: relative;

}

/* stylelint-disable-next-line keyframes-name-pattern */
@keyframes Select-animation-spin {
	to { transform: rotate(1turn); }
}

/* stylelint-disable-next-line keyframes-name-pattern */
@keyframes Select-animation-spin {
	to { -webkit-transform: rotate(1turn); }
}
