// OVERWRITABLE DEFAULTS

$select-input-bg:                   #f8fafd;
$select-input-border-color:         #dfe3ea;
$select-input-bg-disabled:          $select-input-border-color;
$select-input-placeholder:          #363c4c;
$select-input-border-focus:         $select-input-border-color;
$select-input-hover-box-shadow:     none;
$select-input-box-shadow-focus:     0 1px 4px 0 rgb(54 60 76 / 8%), 0 0 1px 0 rgb(0 0 0 / 4%);
$select-input-height:               42px;
$select-input-border-radius:        2px;
$select-input-border-width:         1px;
$select-text-color:                 #363c4c;
$select-menu-max-height:            250px;
$select-menu-zindex:                1000;
$select-menu-box-shadow:            0 8px 16px 0 rgb(54 60 76 / 8%), 0 4px 8px 0 rgb(0 0 0 / 4%);
$select-option-bg:                  #fff;
$select-option-focused-bg:          $select-input-bg;
$select-option-selected-bg:         #f4f6f9;
$select-padding-vertical:           13px;
$select-padding-horizontal:         14px;
$select-clear-size:                 14px;
$select-clear-color:                #a3abbd;
$select-clear-hover-color:          #363c4c;
$select-clear-width:                32px;
$select-item-border-radius:         2px;
$select-item-gutter:                8px;
$select-item-padding-vertical:      4px;
$select-item-padding-horizontal:    10px;
$select-item-font-size:             13px;
$select-item-color:                 #363c4c;
$select-item-bg:                    #dfe3ea;
$select-item-border-color:          #a3abbd;
$select-item-hover-color:           darken($select-item-color, 5%);
$select-item-hover-bg:              darken($select-item-bg, 5%);
$select-item-disabled-color:        #333 !default;
$select-item-disabled-bg:           #fcfcfc;
$select-item-disabled-border-color: darken($select-item-disabled-bg, 10%);

/*
// control options
$select-input-internal-height:      ($select-input-height - ($select-input-border-width * 2)) !default;
$select-link-hover-color:           $select-input-border-focus !default;

// menu options
$select-option-color:               lighten($select-text-color, 20%) !default;
$select-option-focused-color:       $select-text-color !default;
$select-option-selected-color:      $select-text-color !default;
$select-option-disabled-color:      lighten($select-text-color, 60%) !default;
$select-noresults-color:            lighten($select-text-color, 40%) !default;

// arrow indicator
$select-arrow-color:                #999 !default;
$select-arrow-color-hover:          #666 !default;
$select-arrow-width:                5px !default;

// loading indicator
$select-loading-size:               16px !default;
$select-loading-color:              $select-text-color !default;
$select-loading-color-bg:           $select-input-border-color !default;
*/

@import "../../../../../shared/libs/react-select/scss/default";
